<template>
  <section class="column section-height">
    <div class="columns is-centered is-vcentered">
      <div class="column is-8">
        <table class="resume-data-table">
          <tr>
            <th>EQUIPO</th>
            <td class="has-text-centered">
              <figure class="is-inline-block image is-32x32">
                <img :src="'https://az755631.vo.msecnd.net/teams-80/' + homeTeam.team_id + '.png'" />
              </figure>
            </td>
            <td class="has-text-centered">
              <figure class="is-inline-block image is-32x32">
                <img :src="'https://az755631.vo.msecnd.net/teams-80/' + visitingTeam.team_id + '.png'" />
              </figure>
            </td>
          </tr>
          <tr v-for="(subcategory, name, index) in statisticData" :key="index">
            <th width="40%">
              {{ subcategory.names[subcategoryName] || subcategory.names['0-0'] }}
            </th>
            <td class="has-text-centered">
              <div
                v-if="!showPostMatchData"
                class="postmatch is-clickable"
                @click="
                  showModal(
                    $event,
                    subcategory.homeTeam[homeTeamStatisticsType],
                    leagueAvg(subcategory),
                    subcategory.names,
                    true,
                  )
                "
              >
                {{ subcategory.homeTeam[homeTeamStatisticsType].avgPostGame | toFixed2 }}
              </div>
              <div
                v-else
                class="postmatch is-clickable"
                @click="
                  showModal(
                    $event,
                    subcategory.homeTeam[homeTeamStatisticsType],
                    leagueAvg(subcategory),
                    subcategory.names,
                    true,
                  )
                "
              >
                {{ subcategory.homeTeam[homeTeamStatisticsType].totalPostGame }}
              </div>
              &nbsp;
              <div
                v-if="isLive && showLiveData"
                class="live is-clickable"
                @click="
                  showModal(
                    $event,
                    subcategory.homeTeam[homeTeamStatisticsType],
                    leagueAvg(subcategory),
                    subcategory.names,
                    true,
                  )
                "
              >
                {{ subcategory.homeTeam[homeTeamStatisticsType].totalLive | toFixed2 }}
              </div>
            </td>
            <td class="has-text-centered">
              <div
                v-if="!showPostMatchData"
                class="postmatch is-clickable"
                @click="
                  showModal(
                    $event,
                    subcategory.visitingTeam[visitingTeamStatisticsType],
                    leagueAvg(subcategory),
                    subcategory.names,
                    false,
                  )
                "
              >
                {{ subcategory.visitingTeam[visitingTeamStatisticsType].avgPostGame | toFixed2 }}
              </div>
              <div
                v-else
                class="postmatch is-clickable"
                @click="
                  showModal(
                    $event,
                    subcategory.visitingTeam[visitingTeamStatisticsType],
                    leagueAvg(subcategory),
                    subcategory.names,
                    false,
                  )
                "
              >
                {{ subcategory.visitingTeam[visitingTeamStatisticsType].totalPostGame }}
              </div>
              &nbsp;
              <div
                v-if="isLive && showLiveData"
                class="live is-clickable"
                @click="
                  showModal(
                    $event,
                    subcategory.visitingTeam[visitingTeamStatisticsType],
                    leagueAvg(subcategory),
                    subcategory.names,
                    false,
                  )
                "
              >
                {{ subcategory.visitingTeam[visitingTeamStatisticsType].totalLive | toFixed2 }}
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <ResumeTooltip
      :data="tooltip.data"
      :local="tooltip.local"
      :names="tooltip.names"
      :keyName="tooltip.keyName"
      :leagueAvg="tooltip.leagueAvg"
      :left="tooltip.local"
      :show.sync="tooltip.show"
      :x="tooltip.x"
      :y="tooltip.y"
    />
  </section>
</template>

<script>
import ResumeTooltip from '@/components/Elements/NextMatches/ResumeTooltip';
import { mapGetters, mapState } from 'vuex';
import { avg } from '@/utils/league';

export default {
  name: 'Tabla',
  components: {
    ResumeTooltip,
  },
  data() {
    return {
      tooltip: {
        show: false,
        data: {},
        names: {},
        keyName: '',
        leagueAvg: '',
        showLiveData: false,
        local: false,
        x: 0,
        y: 0,
      },
    };
  },
  computed: {
    ...mapGetters('nextGeneral', [
      'stats',
      'game',
      'homeTeam',
      'visitingTeam',
      'statisticData',
      'homeTeamStatisticsType',
      'visitingTeamStatisticsType',
      'subcategoryName',
      'accomplished',
    ]),
    ...mapState('nextGeneral', ['showLiveData']),
    ...mapGetters('nextGeneral', ['isLive', 'showPostMatchData']),
  },
  methods: {
    showModal(event, data, leagueAvg, names, local) {
      this.tooltip.data = data;
      this.tooltip.local = local;
      this.tooltip.names = names;
      this.tooltip.keyName = this.subcategoryName;
      this.tooltip.leagueAvg = leagueAvg;
      this.tooltip.x = event.pageX;
      this.tooltip.y = event.pageY > 700 ? event.pageY - 250 : event.pageY;
      this.tooltip.show = true;
    },
    leagueAvg(subcategory) {
      return avg(subcategory, this.accomplished);
    },
  },
};
</script>

<style lang="scss" scoped>
table.resume-data-table {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  font-family: 'Circular-Std-Bold';
  border: 1px solid #686a6e;
  line-height: 18px;
  border-radius: 10px;
  -moz-border-radius: 10px;

  th {
    color: #ffffff;
    background-color: #686a6e;
    padding: 0.8rem 0rem 0.8rem 2rem;
    border: 1px inset #dbdbdb;
    font-family: 'Circular-Std-Medium';
  }

  td {
    color: #000000;
    background-color: #ffffff;
    border: 1px solid #dbdbdb;
  }

  .live {
    font-family: 'Circular-Std';
    display: inline-block;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.005rem;
    text-align: center;
    color: #ffffff;
    background-color: #77a405;
    border-radius: 8px;
    width: 40px;
  }

  tr {
    height: 10px;
    padding-top: 5px;

    &:last-child {
      th {
        &:first-child {
          border-bottom-left-radius: 10px;
        }
      }

      td {
        &:last-child {
          border-bottom-right-radius: 10px;
        }
      }
    }

    &:first-child {
      th {
        &:first-child {
          border-top-left-radius: 10px;
          font-family: 'Circular-Std-Bold';
        }
      }

      td {
        &:last-child {
          border-top-right-radius: 10px;
        }
      }
    }
  }
}

.has-text-centered {
  padding: 0.8rem 0rem 0.2rem 0rem;
}

.section-height {
  align-self: center;
}

@media (max-width: 360px) {
  table.resume-data-table {
    th {
      padding-left: 1px !important;
    }
  }
}
.postmatch {
  width: 30%;
  display: inline-flex;
  text-align: center !important;
  align-items: center;
  justify-content: center;
}
.postmatch:hover {
  color: #77a405 !important;
}
.live:hover {
  background-color: #658909 !important;
}
</style>
